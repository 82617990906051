import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css'

import { injectIntl, useIntl } from "gatsby-plugin-intl"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Recycling = () => {
  const intl = useIntl()
  const data = useStaticQuery(
    graphql`
    query {
      allContentfulProductPage {
        nodes {
          id
          title
          node_locale
          seoTitle
          section
          picture{
            fluid{
              ...GatsbyContentfulFluid_withWebp
            }
          }
          seoDescription {
            seoDescription
          }
          childContentfulProductPageAboutPageContentRichTextNode {
            json
          }
        }
      }
    }`)
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "prod_recycling" })} />
      <article>


        {
          data.allContentfulProductPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'recycling').map(artl => {
            return (
              <section className="prodRow prodLeft" key={artl.id}>
                <section>
                  <h1>{artl.title}</h1>
                  {documentToReactComponents(artl.childContentfulProductPageAboutPageContentRichTextNode.json)}
                </section>
                <figure onContextMenu={(e) => e.preventDefault()} role='presentation'>
                  <Img fluid={artl.picture.fluid} alt={artl.title} title={artl.title} />
                </figure>
              </section>
            )
          })
        }

      </article>
    </Layout>
  )
}

export default injectIntl(Recycling)
